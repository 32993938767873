'use strict';

import escape from 'lodash.escape';

export default class Search {
	constructor() {
		// console.log(location.search.substr(1).split(/&/));

		var get = {};
		var searchParameters = location.search.substr(1).split(/&cx/);

		for(var i = 0; i < searchParameters.length; i++){
			var tmp = searchParameters[i].split(/=/);
			if (tmp[0] != "") {
				get[decodeURIComponent(tmp[0])] = decodeURIComponent(tmp.slice(1).join("").replace(/\+/g, " "));
			}
		}
		var keyword = 'Search Result for "' + get.q + '"';
		$('.searchHeader').text(keyword);
	}
}
