'use strict';

export default class Weather {
	constructor() {
		var counter = 1;
		$('.plus-minus').on('click', function(e) {
			e.preventDefault();
			$(this).parent().next('.accordion-panel').not(':animated').slideToggle();
			if (counter % 2 == 1){
				$('.plus-minus').attr('src', '/Cwp/assets/ubin/images/minus.png');
				counter++;
			}
			else {
				$('.plus-minus').attr('src', '/Cwp/assets/ubin/images/plus.png');
				counter++;
			}
		});


		//api url
		var wURL = 'https://api.data.gov.sg/v1/environment/2-hour-weather-forecast';
		var tURL = 'https://api.data.gov.sg/v1/environment/air-temperature';
		var iconJSON = '/api/ubinapi/weatherIcons';

		var weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
		var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

		var wCondition = [];

		var d, wday, day, month, year;
		var d = new Date();

		wday = weekday[d.getDay()];
		day = d.getDate();
		month = months[d.getMonth()];
		year = d.getFullYear();


		//make date string
		var makeDate = day + ' ' + month + ', ' + year;

		$('.day').text(wday);
		$('.date').text(makeDate);


		// api call for weather state
		$.ajax({
			url: wURL,
			type: "GET",
			dataType: 'json',
			headers: {"api-key": "QepTgw4n7fJlLadTPGFKG6Ea1Z0NXQAs"},
			success: function(data) {
				// console.log(data);
				var forecastArr = data.items[0].forecasts;
				var length = data.items[0].forecasts.length;
				var weatherState = '';
				var weatherIndex;
				var wCondLength;


				// load weather state and icon JSON
				$.ajax({
					url: iconJSON,
					type: "GET",
					dataType: "json",
					success: function(data) {
						// console.log(data);
						wCondition = data;
						wCondLength = wCondition.length;

						// get weather condition
						for(var i = 0; i < length; i++) {
							if(forecastArr[i].area == 'Pulau Ubin') {
								$('.weather-state').text(forecastArr[i].forecast);
								weatherState = forecastArr[i].forecast;
								break;
							}
						}

						// get index that corresponds to the weather condition from array containing the image location
						for(var j = 0; j < wCondLength; j++) {
							if(wCondition[j].weather == weatherState) {
								weatherIndex = j;
								break;
							}
						}

						// switch case to use respective weather icon
						switch(weatherIndex) {
							case 0:
								$('.weather-icon').attr("src", wCondition[weatherIndex].imageLoc);
								$('.weather-icon').attr("alt", weatherState);
								break;
							case 1:
								$('.weather-icon').attr("src", wCondition[weatherIndex].imageLoc);
								$('.weather-icon').attr("alt", weatherState);
								break;
							case 2:
								$('.weather-icon').attr("src", wCondition[weatherIndex].imageLoc);
								$('.weather-icon').attr("alt", weatherState);
								break;
							case 3:
								$('.weather-icon').attr("src", wCondition[weatherIndex].imageLoc);
								$('.weather-icon').attr("alt", weatherState);
								break;
							case 4:
								$('.weather-icon').attr("src", wCondition[weatherIndex].imageLoc);
								$('.weather-icon').attr("alt", weatherState);
								break;
							case 5:
								$('.weather-icon').attr("src", wCondition[weatherIndex].imageLoc);
								$('.weather-icon').attr("alt", weatherState);
								break;
							case 6:
								$('.weather-icon').attr("src", wCondition[weatherIndex].imageLoc);
								$('.weather-icon').attr("alt", weatherState);
								break;
							case 7:
								$('.weather-icon').attr("src", wCondition[weatherIndex].imageLoc);
								$('.weather-icon').attr("alt", weatherState);
								break;
							case 8:
								$('.weather-icon').attr("src", wCondition[weatherIndex].imageLoc);
								$('.weather-icon').attr("alt", weatherState);
								break;
							default:
								$('.weather-icon').attr("src", wCondition[weatherIndex].imageLoc);
								$('.weather-icon').attr("alt", weatherState);
								break;
						}
					}
				});
			}
		});



		// api call for air temperature
		$.ajax({
			url: tURL,
			type: "GET",
			dataType: 'json',
			headers: {"api-key": "ptBGPcseGCqrHQDnxAe7FJvaY6vwha4Z"},
			success: function(data) {
				// console.log(data);
				var stationArr = data.metadata.stations;
				var stationLength = stationArr.length;
				var stationID = '';

				//get station ID for Pulau Ubin
				for(var i = 0; i < stationLength; i++) {
					if(stationArr[i].name == 'Pulau Ubin') {
						stationID = stationArr[i].id;
						break;
					}
				}

				var tempArr = data.items[0].readings;
				var length = data.items[0].readings.length;

				// get temperature value using Pulau Ubin station ID
				for(var i = 0; i < length; i++) {
					if(tempArr[i].station_id == stationID) {
						$('.temp-value').text(tempArr[i].value);
						break;
					}
				}
			}
		});
	}
}
