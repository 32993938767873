'use strict';

import $ from 'jquery';

export default class FriendsOfUbin {
  constructor() {
    if($('.highlights').length) {
        let $highlights = $('.highlights'),
            $highlightsHidden = $('.hidden', $highlights),
            $highlightsShowBtn = $('.highlights__showMoreBtn', $highlights);

      $highlightsShowBtn.on('click', function() {
          $highlightsHidden.removeClass('hidden');
          $(this).hide();
      });
    }
  }
}
