'use strict';

import 'select2';

export default class Newsroom {
	constructor() {
		$('.filters').select2({
			minimumResultsForSearch: Infinity
		});

		$('.js-newsroom-filter').on('click', function(e) {
			e.preventDefault();

			let monthParameter = '',
				yearParameter = '',
				pageParameter = '';

			let currentPageURL = window.location.href;
			let lastindex = currentPageURL.lastIndexOf('?');
			let trimmedURL;
			if(lastindex !== -1) {
    			trimmedURL = currentPageURL.substring(0, lastindex);
			} else {
				trimmedURL = currentPageURL;
			}

			monthParameter = $('#date-filter option:selected').val();
			yearParameter = $('#year-filter option:selected').val();

			let filterQuery = `?month=${monthParameter}&year=${yearParameter}`;
			let newURL = `${trimmedURL}${filterQuery}`;

			window.location.href = newURL;

		});
	}
}
