'use strict';

import $ from 'jquery';
import moment from 'moment';
import 'fullcalendar';
import tippy from 'tippy.js';

export default class Calendar {
  constructor() {

    if(!$('#calendarPage').length) {
      return false;
    }

    let calendar = $("#calendar"),
      mobileEventDetails = $("#mobileEventDetails"),
      display = mobileEventDetails.find('#display'),
      filterValue = $("#filterValue"),
      filterBtn = $("#filters").find("button"),
      currentMonth = $(".fc-header-toolbar").find("h2"),
      selectMonth = $(".selectMonth"),
      allMonths = ["January","February","March","April","May","June","July","August","September","October","November","December"],
      allDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
      today = new Date(),
      thisDay = today.getDate(),
      thisYear = today.getFullYear(),
      thisMonth = today.getMonth(), // zero indexed
      dayOfWeek = today.getDay(),
      dateDisplayed = selectMonth.val(),
      prettyMonth = allMonths[thisMonth],
      prettyDay = allDays[dayOfWeek - 1],
      mobileEventList = $('.mobileEventList'),
      apiEndpoint = $('#calendarPage').data('url'),
      categoryArray = ["Camps and Organised Activities", "Ubin Volunteers", "Guided Walks", "Nature"],
      categoryClassArray = ["campsandorganisedactivities", "ubinvolunteers", "guidedwalks", "nature"],
      abbreviatedCategoryArray = ["C/OA", "UV", "GW", "N"],
      prevBtn = $('.prevBtn'),
      nextBtn = $('.nextBtn'),
      currMonth = thisMonth+1,
      currYear = thisYear,
      upperLimitDate = `${currYear+1}-${currMonth + 1}`,
      lowerLimitDate = `${currYear}-${currMonth - 1}`;


    let initTippy = function() {
      let eventParentTippy = tippy('.eventParent', {
        html: '.eventHover',
        arrow: true,
        interactive: true,
        theme: 'default',
        // trigger: 'click',
        onShow: function(instance) {

          let tooltipContainer = $(instance.popper)[0].children[0].children[1],
            target = $('.eventParent:hover'),
            dateHolder = target.closest('.fc-day'),
            date = dateHolder.data('date'),
            categoryFocus = target.data('category'),
            tippyCatClassArray = ["campsandorganisedactivities", "ubinvolunteers", "guidedwalks", "nature"],
            prettyDate = prettifyDate(date),
            prettyCategoryIdx = tippyCatClassArray.indexOf(categoryFocus),
            prettyCategory = categoryArray[prettyCategoryIdx],
            html = `
            <div class="tooltipWrap" style="min-width: 250px; padding-left: 15px; padding-bottom: 15px;">
            <span class="date" style="padding-top: 10px">${prettyDate}</span>
            <span class="category ${categoryFocus}" style="font-size: 9pt">${prettyCategory}</span>
            </div>`;

          calendarData.forEach((each, idx) => {
            let classifiedCategory = classifyCategory(each.category);

            if (each.start == date && classifiedCategory == categoryFocus) {
              let title = each.title,
              location = each.location,
              time = each.time,
              url = each.url;

              html += `
              <div class="eachEvent" style="text-align: left; padding-left: 15px;">
              <h3 style="margin-bottom: 5px;">${title}</h3>
              <span style="font-size: 10pt; display: block">Location: ${location}</span>
              <span style="font-size: 10pt; display: block; padding-top: 5px">Time: ${time}</span>`;

              if(each.end) {
                html += `<span style="font-size: 10pt; display: block; padding-top: 5px">End: ${prettifyDate(each.end)}</span>`;
              }

              html += `
              <a href="${url}" target="_blank" style="font-size: 10pt; font-weight: bolder; padding-top: 5px; display: block;">More info</a>
              </div>`;
            }
          });

          // html += `</div>`;

          $(tooltipContainer).html(html);
        },
        onHide: function(instance) {
          let tooltipContainer = $(instance.popper)[0].children[0].children[1];
          $(tooltipContainer).html('')
        }
      });
    }

    let handleMonths = function() {

      let goToDate,
        option,
        val,
        nowMonth,
        nowYear;

      prevBtn.attr('disabled', true);

      prevBtn.on('click', function() {

        if ($(window).width() > 767) {
          option = $('.wrapSelect-desktop .selectMonth option:selected');
        } else {
          option = $('.wrapSelect-mobile .selectMonth option:selected');
        }

        // the date
        val = option.val();
        val = val.split('-');

        nowMonth = val[1];
        nowYear = val[0];

        nowMonth--;
        nowMonth = (nowMonth < 1) ? 12 : nowMonth;
        nowYear = (nowMonth == 12) ? nowYear - 1 : nowYear;

        nextBtn.attr('disabled', false);
        goToDate = `${nowYear}-${nowMonth}`;

        if (goToDate != lowerLimitDate) {
          $("#calendar").fullCalendar("gotoDate", goToDate);
          prevBtn.attr('disabled', false);
        } else {
          nowMonth++;
          prevBtn.attr('disabled', true);
        }
        $('.selectMonth option:selected').prev().prop('selected', true);
        initTippy();
      });

      nextBtn.on('click', function() {

        // setTimeout(function() {
        //   filterItems();
        // }, 5);

        option = $('.selectMonth option:selected');

        val = option.val();
        val = val.split('-');

        nowMonth = val[1];
        nowYear = val[0];

        nowMonth++;

        nowMonth = (nowMonth > 12) ? 1 : nowMonth;
        nowYear = (nowMonth == 1) ? nowYear + 1 : nowYear;

        prevBtn.attr('disabled', false);
        goToDate = `${nowYear}-${nowMonth}`;

        if (goToDate != upperLimitDate) {
          $("#calendar").fullCalendar("gotoDate", goToDate);
          nextBtn.attr('disabled', false);
        } else {
          nowMonth--;
          nextBtn.attr('disabled', true);
        }
        $('.selectMonth option:selected').next().prop('selected', true);
        initTippy();
      })
    }();

    // remove duplicates from array
    let uniq = (a) => {
      return Array.from(new Set(a));
    }

    let prettifyDate = (date) => {
      date = date.split("-");
      let year = parseInt(date[0]),
      month = parseInt(date[1]),
      day = parseInt(date[2]);

      month = allMonths[month - 1]
      return `${day} ${month} ${year}`;
    }

    let calendarData = $.parseJSON($.ajax({
      url: apiEndpoint,
      dataType: 'json',
      async: false,
    }).responseText);

    // show today in events display
    display.html(`Today - ${allMonths[thisMonth]} ${thisDay}, ${thisYear}`);

    let classifyCategory = (category) => {
      return category.replace(/\s/g, '').replace('&', 'and').toLowerCase();
    }

    let getDayInFull = (indexDay) => {
      indexDay--;
      indexDay = (indexDay < 0) ? 6 : indexDay;
      return allDays[indexDay];
    }

    let isItToday = (year, month, day) => {
      if (year == thisYear && month == thisMonth + 1 && day == thisDay) {
        return true;
      }
    }

    let getMonthOptions = () => {
      let currentMonth = thisMonth,
      optionsLength = selectMonth.find('option').length;
      if (optionsLength < 12) {
        for (let i = 1; i < 14; i++) {
          let newMonth = i + currentMonth,
          newYear = thisYear,
          optionValue,
          monthInFull,
          optionHolder;

          if (newMonth > 12) {
            newMonth -= 12;
            newYear++;
          }

          optionValue = `${newYear}-${newMonth}`;
          monthInFull = allMonths[newMonth - 1];

          optionHolder = `<option value=${optionValue}>${monthInFull} ${newYear}</option>`;
          selectMonth.append(optionHolder);
        }
      }
    }

    let renderEventList = (eventName, time, category, eventLocation, eventUrl) => {

      if (eventName && time && category) {

        let cleanCategory = classifyCategory(category),
        html = `<div class="event ${cleanCategory}-wrapper">`,
        categoryHolder = `<span class="category ${cleanCategory}">${category}</span>`,
        titleHolder = `<h3 class="eventName">${eventName}</h3>`,
        locationHolder = eventLocation ? `<span class="location">${eventLocation}</span>` : '',
        timeHolder = time ? `<span class="timeHolder">${time}</span>` : '',
        urlHolder = eventUrl ? `<a href="${eventUrl}" target="_blank" class="moreinfo">More Info</a>` : '';
        html = html + categoryHolder + titleHolder + locationHolder + timeHolder + urlHolder;
        html += `</div>`;
        mobileEventList.append(html);
      }
    }

    getMonthOptions();

    selectMonth.on("change", () => {

      let option;

      if ($(window).width() > 767) {
        option = $('.wrapSelect-desktop .selectMonth option:selected');
      } else {
        option = $('.wrapSelect-mobile .selectMonth option:selected');
      }

      // the date
      let val = option.val(),
        lowerLimit = `${currYear}-${currMonth}`;

      if (val != lowerLimit) {
        prevBtn.attr('disabled', false);
      }

      $("#calendar").fullCalendar("gotoDate", val);

      getMonthOptions();
      initTippy();
    });

    // events
    let filtersToShow = categoryClassArray;

    // filter
    filterBtn.on("click", function() {

      $(this).toggleClass("active");

      let filterVal = $(this).data("value");


      if ($(this).hasClass("active")) {

          filtersToShow.push(filterVal);

      } else {
        let idx = filtersToShow.indexOf(filterVal);
        if (idx > -1) {
          filtersToShow.splice(idx, 1);
        }
      }

      filtersToShow = uniq(filtersToShow);

      $('.event').addClass('hide');
      $('.eventParent').addClass('hide');

      if (filtersToShow.length > 0) {
        filtersToShow.forEach((elem, idx) => {
          if ($('.event .category').hasClass(elem)) {
            $(`.${elem}-wrapper`).removeClass('hide');
          }
          $(`.${elem}-event`).removeClass('hide');
        })
      }
    });

    let startMonth = thisMonth + 1,
    endMonth = startMonth + 2;

    startMonth = (startMonth < 10) ? `0${startMonth}` : `${startMonth}`,
    endMonth = (endMonth < 10) ? `0${endMonth}` : `${endMonth}`;

    calendar.fullCalendar({
      height: "auto",
      firstDay: 1,
      events: calendarData,
      eventRender: function(event, element, view) {
        $(element).each(function() {
          $(this).attr('date-category', event.category.toLowerCase());
          $(this).attr('date-num', event.start.format('YYYY-MM-DD'));
        })
      },
      eventAfterAllRender: function(view){

        for( let cDay = view.start.clone(); cDay.isBefore(view.end) ; cDay.add(1, 'day') ) {

          let dateNum = cDay.format('YYYY-MM-DD');

          let dayEl = $('.fc-day[data-date="' + dateNum + '"]');

          // each of the day to append html into
          categoryArray.forEach((each, idx) => {
            let eventCount = $(`.fc-event[date-category="${each.toLowerCase()}"][ date-num="${dateNum}"]`).length;

            if(eventCount) {

              let txt = (eventCount == 1) ? 'Event' : 'Events',
                newCatClassArray = ["campsandorganisedactivities", "ubinvolunteers", "guidedwalks", "nature"],
                categoryClass = newCatClassArray[idx],
                categoryLabel = abbreviatedCategoryArray[idx];

              let html = `
              <div class="eventParent ${categoryClass}-event" data-category="${categoryClass}"><span class="catLabel ${categoryClass}">${categoryLabel}</span>
              <span class="event-count"><span class="count">${eventCount}</span> ${txt}</span></div>`

              dayEl.append(html);
            }
          })
        }

        let itemsToHide = [];

        filterBtn.each((idx, each) => {

          let $each = $(each);

          if (!$each.hasClass('active')) {
            itemsToHide.push($each);
          }
        });

        if (itemsToHide.length) {
          itemsToHide.forEach((item) => {
            let $item = $(item),
              value = $item.data('value');
            $(`.${value}-event`).addClass('hide');
          })
        }
      },
      dayClick: function(date, jsEvent, view) {

        let dateChosen = date.format(),
        dateChosenFormatted = new Date(dateChosen),
        idxDayOfWeek = dateChosenFormatted.getDay();

        dateChosen = dateChosen.split("-");

        let dayInFull = getDayInFull(idxDayOfWeek);

        let year = dateChosen[0],
        month = dateChosen[1],
        day = dateChosen[2];

        let clickedOnToday = isItToday(year, month, day);

        if (clickedOnToday) {
          display.html(`Today - ${prettyMonth} ${thisDay}, ${thisYear}`);
        } else {
          month = parseInt(month) - 1; // to accomodate zero indexing
          display.html(`${dayInFull} - ${allMonths[month]} ${day}, ${year}`);
        }

        // clear all eventsContainer
        mobileEventList.html('');

        $("#calendar").fullCalendar("clientEvents", function(event) {
          if (
            moment(date).format("YYYY-MM-DD") ==
            moment(event.start).format("YYYY-MM-DD")
          ) {
              let title = event.title,
              time = event.time,
              category = event.category,
              location = event.location,
              url = event.url;

              renderEventList(title, time, category, location, url);
          }
        });

        // start of fx
        // let allMobileEvents = $('.event', mobileEventList);
        // 
        //
        // $.each(allMobileEvents, function(idx, each) {
        //   $(each).removeClass('event');
        // })
        //
        // allMobileEvents.sort(function(a, b) {
        //   let an = $(a).attr('class'),
        //     bn = $(b).attr('class');
        //
        //   an = an.split('-');
        //   an = an[0];
        //
        //   bn = bn.split('-');
        //   bn = bn[0];
        //
        //   if (an == 'ubinvolunteers' && bn == 'guidedwalks') {
        //     return -1;
        //   }
        // })
        //
        // console.log(allMobileEvents);

        // end of fx
      }
    });

    let eventParent = $('.eventParent'),
    tooltip = $('.eventHover');

    initTippy();
  }
}
