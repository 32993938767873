'use strict';

export default class ActivityCalendar {
	constructor() {

		$('.guided-walks-list').slick({
	  		infinite: false,
	  		autoplay: false,
	  		dots: true,
	  		arrows: false,
	  		initialSlide: 3
	  	});

		$(function(){
			$('li.walks-nav').on('click', function(e){
				e.preventDefault();
				var slideid = $(this).index();
				$('.walk-active').removeClass('walk-active');
				$(this).addClass('walk-active');
				$('.guided-walks-list').slick('slickGoTo', slideid);
				// console.log(slideid);
			});

			$('.walk-bg-img').each(function() {
				var imgSrc = $(this).attr('src');
				$(this).parent().css('background-image', 'url(' + imgSrc + ')');
				// console.log(imgSrc);
			});
		});

	}
}
