'use strict';

import escape from 'lodash.escape';

export default class StandardHeader {
	constructor() {
		$('.navDropdown').on('mouseover', function(e){
			$(this).children('.navDropdownList').show();
		});

		$('.navDropdown').on('mouseout', function(e){
			$(this).children('.navDropdownList').hide();
		});

		$('.ubinBtn').on('mouseover', function(e){
			$(this).next('.navDropdownList').show();
		});

		$('.ubinBtn').on('mouseout', function(e){
			$(this).next('.navDropdownList').hide();
		});

		function mobileMenuAnimation(stackOpacity, crossOpacity, stackWidth, crossWidth) {
			$('.bar1').animate({
				opacity: stackOpacity,
				width: stackWidth
			}, 500
			);
			$('.bar2').delay(200).animate({
				opacity: stackOpacity,
				width: stackWidth
			}, 500
			);
			$('.bar3').delay(400).animate({
				opacity: stackOpacity,
				width: stackWidth
			}, 500
			);
			$('.bar4').delay(600).animate({
				opacity: crossOpacity,
				width: crossWidth
			}, 500
			);
			$('.bar5').delay(700).animate({
				opacity: crossOpacity,
				width: crossWidth
			}, 500
			);
		}

		// mobile nav jquery
		$('.m-nav-icon').on('click', function(e){
			e.preventDefault();
			$('.slideDown').not(':animated').height('100%');
			mobileMenuAnimation('0.0','1.0', '0', '25px');
			if($('.m-search-container').hasClass('open')) {
				$('.m-search-container').removeClass('open');
			}
		});

		$('.m-search').on('click', function(e){
			e.preventDefault();
			if($('.m-search-container').hasClass('open')) {
				$('.m-search-container').removeClass('open');
			} else {
				$('.m-search-container').addClass('open');
			}

			$('.slideDown').height('0px');
			$('.slideLeft').width('0px');
			mobileMenuAnimation('1.0','0.0','25px','0');

		});

		$('.m-btn-search').on('click', function(e){
			// e.preventDefault();
			if($('.m-search-container').hasClass('open')) {
				$('.m-search-container').removeClass('open');
			}
			var url = window.location.origin;
			var searchValue = $('#m-search-site').val();
			// var searchOption = $('#searchDropdown').val();
			var pageURL = $(this).parent().data('url');
			var keywords = searchValue.split(" ");
			var keywordLength = keywords.length;
			var query = '';

			for (var i = 0; i < keywordLength; i++){
				query += keywords[i];
				query += "+";
			}

			query = query.slice(0, -1);
			query = escape(query).replace(/&amp;/g, '%26');
			var searchQuery = url + pageURL + '?q=' + query + '&cx=ubin';
			window.location.href = searchQuery;
		});

		$('#m-search-site').keyup(function(e){
			if(e.keyCode == 13){
				$(".m-btn-search").click();
			}
		});

		$('.m-nav-close').on('click', function(e){
			e.preventDefault();
			$('.slideDown').not(':animated').height('0');
			$('.slideLeft').not(':animated').width('0');
			mobileMenuAnimation('1.0','0.0','25px', '0');
		});

		$('.next').on('click', function(e){
			e.preventDefault();
			$(this).next().not(':animated').width('100%');
		});

		$('.back').on('click', function(e){
			e.preventDefault();
			$(this).parent().not(':animated').width('0');
		});

		//comment out for bug#62740
		// $('.mnav-link').on('click', function(e) {
		// 	e.preventDefault();
		// 	let url = window.location.origin;
		// 	if($(this).hasClass('m-home')) {
		// 		window.location.href = `${url}`;
		// 	}
		// 	else{
		// 		let link = $(this).children().attr('href');
		// 		window.location.href = `${url}/${link}`;
		// 	}
		// });

		$('#searchSite').on('click', function(e){
			var url = window.location.origin;
			var searchValue = $('#siteSearch').val();
			var searchOption = $('#searchDropdown').val();
			var pageURL = $(this).parent().data('url');
			var keywords = searchValue.split(" ");
			var keywordLength = keywords.length;
			var query = '';

			for (var i = 0; i < keywordLength; i++){
				query += keywords[i];
				query += "+";
			}

			query = query.slice(0, -1);
			query = escape(query).replace(/&amp;/g, '%26');
			var searchQuery = url + pageURL + '?q=' + query + '&cx=' + searchOption;
			window.location.href = searchQuery;
		});

		$('#siteSearch').keyup(function(e){
			if(e.keyCode == 13){
				$("#searchSite").click();
			}
		});
	}
}
