'use strict';

import 'select2';

export default class Contactform {
	constructor() {
		$('.scfDropList').select2({
			minimumResultsForSearch: Infinity
		});
	}
}
