'use strict';

import $ from 'jquery';
import 'slick-carousel';

export default class Slider {
  constructor() {

    if($('.slider').length) {
      /*=======================================================
      >> Slider setttings
      =========================================================*/
      function slickSettings(mode) {
        if(mode == 'center') {
          return {
            dots: false,
            infinite: true,
            centerMode: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: '<button class="slide-next"><i class="icon icon-angle-right"></i><span class="visuallyhidden">Button to view the next items of the slider</span></button>',
            prevArrow: '<button class="slide-prev"><i class="icon icon-angle-left"></i><span class="visuallyhidden">Button to view the previous items of the slider</span></button>',
            // responsive: [
            //   {
            //     breakpoint: 1280,
            //     settings: {
            //       arrows: false,
            //       centerMode: true,
            //       centerPadding: '40px',
            //       slidesToShow: 1
            //     }
            //   }
            // ]
          }
        } else {
          return {
            dots: true,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: '<button class="slide-next"><i class="icon icon-angle-right"></i><span class="visuallyhidden">Button to view the next items of the slider</span></button>',
            prevArrow: '<button class="slide-prev"><i class="icon icon-angle-left"></i><span class="visuallyhidden">Button to view the previous items of the slider</span></button>'
          }
        }
      }
      if($('.fou-slider').length) {
        $('.slider').slick(slickSettings('center'));
      } else {
        $('.slider').slick(slickSettings());
      }
    }
  }
}
