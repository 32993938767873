'use strict';

import $ from 'jquery';

export default class TableResponsive {
	constructor() {

		$(window).on('resize.tableResponsive', function() {
			$('table').each(function() {
				let $this = $(this);

				if (!$this.parents('.table-responsive').length) {
					if ($this.width() > $this.parent().width()) {
						$this.wrap('<div class="table-responsive" />');
					}
				}
			});
		}).trigger('resize.tableResponsive');

	}
}
