// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';
import Slider from '../_modules/molecules/slider/slider';
import StandardHeader from '../_modules/organisms/standard-header/standard-header';
// import Header from '../_modules/organisms/standard-header/standard-header';
import Weather from '../_modules/organisms/weather/weather';
import PlanTrip from '../_modules/organisms/plan-trip/plan-trip';
import ActivityCalendar from '../_modules/organisms/activity-calendar/activity-calendar';
// import SocialGallery from '../_modules/organisms/social-gallery/social-gallery';
import Newsroom from '../_modules/organisms/newsroom/newsroom';
import Onemap from '../_modules/organisms/onemap/onemap';
import ContactForm from '../_modules/organisms/contactform/contactform';
import EventsListing from '../_modules/organisms/eventslisting/eventslisting';
import Search from '../_modules/organisms/search/search';
import FriendsOfUbin from '../_modules/organisms/friends-of-ubin/friends-of-ubin';
import Pagination from '../_modules/molecules/pagination/pagination';
import customSelect from '../Cwp/assets/ubin/scripts/customSelect';
import SiteFooter from '../_modules/organisms/site-footer/site-footer';
import TableResponsive from '../_modules/molecules/table-responsive/table-responsive';
import Sidenav from '../_modules/molecules/sidenav/sidenav';
import Calendar from '../_modules/molecules/calendar/calendar';

$(() => {

  // let isDebug = true;
  // new Link(); // Activate Link modules logic
  // new Header();

  let pathname = window.location.pathname,
    header = $('header');

  if (pathname == '/pulau-ubin') {
    header.addClass('homepageHeader');
  }

  customSelect($('.events-filter'));

  //push footer down for standard-pages
  let windowHeight = $(window).height(),
      headerHeight = $('.header-container').height(),
      contentHeight = $('.standard-body-wrapper').height(),
      searchContentHeight = $('.search-result-wrapper').height(),
      footerHeight = $('.footer-container').height(),
      formContainer = $('.standard-content').children('form.text-left'),
      formGroup = formContainer.children('.form-group'),
      formButton = formContainer.children('.form-submit-border'),
      footerForm = $('.m-footer').children('form');

  if((headerHeight + contentHeight + footerHeight) < windowHeight) {
    $('.standard-body-wrapper').css('minHeight', windowHeight - headerHeight - footerHeight);
  }

  if((headerHeight + searchContentHeight + footerHeight) < windowHeight) {
    $('.search-result-wrapper').css('minHeight', windowHeight - headerHeight - footerHeight);
  }

  new Slider();

  new Sidenav();

  new FriendsOfUbin();

  new SiteFooter();
  new Search();
  if ($('.plan-trip-container').length) {
    new Weather();
    new PlanTrip();
    new ActivityCalendar();
  }
  // new SocialGallery();
  new StandardHeader();
  if ($('.newsroom-main-wrap').length) {
    new Newsroom();
    new Pagination();
  }
  if ($('.contactform-wrapper').length) {
    new ContactForm();
  }
  if ($('.onemap-container').length) {
    new Onemap();
  }
  if ($('.event-listing-main').length) {
    new EventsListing();
    new Pagination();
  }

  if ($('table').length) {
    new TableResponsive();
  }

  new Calendar();
  // console.log('Welcome to Yeogurt!');

  //Adding Class to contact form
  if (formContainer.length) {
    setTimeout( function() {
      formContainer.addClass('scfForm plForm');
      formGroup.addClass('scfSingleLineTextBorder');
      formButton.addClass('scfSubmitButtonBorder');
      footerForm.addClass('footer__subscribe m-subscribe');

      formGroup.children('label.control-label').addClass('scfSingleLineTextLabel');
      formGroup.children('input.text-box').addClass('scfSingleLineTextBox');
      formGroup.children('select').addClass('scfDropList');
      formGroup.find('textarea[class="form-control"]').addClass('scfMultipleLineTextBox');
      formButton.children('input.btn-default').addClass('scfSubmitButton');

      // formContainer.show();

      // console.log('Form SHOW!!!');
    }, 500);
  }

});
