'use strict';

import 'leaflet';
import 'leaflet-sidebar';
import 'esri-leaflet';
import 'select2';
import doT from 'dot';
import queryString from 'query-string';

export default class Onemap {
  constructor() {
    $('.discover-by').select2({
      minimumResultsForSearch: Infinity
    });

    //button handlers

    $('.mobile-filter-tabs ul li').on('click', function(e){
      e.preventDefault();
      $(this).siblings().removeClass('active');
      $(this).addClass('active');
      var optionValue = $(this).attr('id');
      var categoryClass = ".accordion-" + optionValue;
      $('.mobile-accordion').not(':animated').slideUp(150);
      $(categoryClass).not(':animated').delay(150).slideToggle(500);
    });

    $('.mobile-filter-close p').on('click', function(e){
      $(this).parent().parent().slideUp(250);
    });

    $('.map-accordion ul li').on('click', function(e){
      e.preventDefault();
      var getClass = $(this).attr('class');
      var splitClass = getClass.split(" ");
      // var trailClass = "." + splitClass[0];
      var twinButton = ".mobile-filter-buttons ul li." + splitClass[0];
      // console.log(twinButton);
      if(!$(this).hasClass('active')) {
        $(this).addClass('active');
        $(twinButton).addClass('active');
        // $(trailID).show();   //show respective trail
      }
      else {
        $(this).removeClass('active');
        $(twinButton).removeClass('active');
        // $(trailID).hide();   //hide respective trail

      }
    });

    $('.mobile-filter-buttons ul li').on('click', function(e){
      e.preventDefault();
      var getClass = $(this).attr('class');
      var splitClass = getClass.split(" ");
      // var trailID = "#" + splitClass[0];
      var twinButton = ".map-accordion ul li." + splitClass[0];

      if(!$(this).hasClass('active')) {
        $(this).addClass('active');
        $(twinButton).addClass('active');
        // $(trailID).show();   //show respective trail
      }
      else {
        $(this).removeClass('active');
        $(twinButton).removeClass('active');
        // $(trailID).hide();   //hide respective trail

      }
    });

    $('.js-map-accordion1, .js-map-visHidden1').on('click', function(e){
      e.preventDefault();
      var optionValue = $('.js-map-option').val();
      var categoryClass = ".map-accordion-" + optionValue;
      if(!$(this).hasClass('js-map-visHidden1')){
        $(this).toggleClass('rotateIcon');
        var text = $('.js-map-visHidden1').text();
        if(text === 'Open'){
          $('.js-map-visHidden1').text('Close');
        }
        else {
          $('.js-map-visHidden1').text('Open');
        }
      }
      else {
        $('.js-map-accordion1').toggleClass('rotateIcon');
        var text = $(this).text();
        if(text === 'Open'){
          $(this).text('Close');
        }
        else {
          $(this).text('Open');
        }
      }
      $(categoryClass).not(':animated').slideToggle();
    });
    $('.js-map-accordion2, .js-map-visHidden2').on('click', function(e){
      e.preventDefault();
      if(!$(this).hasClass('js-map-visHidden2')){
        $(this).toggleClass('rotateIcon');
        var text = $('.js-map-visHidden2').text();
        if(text === 'Open'){
          $('.js-map-visHidden2').text('Close');
        }
        else {
          $('.js-map-visHidden2').text('Open');
        }
      }
      else {
        $('.js-map-accordion2').toggleClass('rotateIcon');
        var text = $(this).text();
        if(text === 'Open'){
          $(this).text('Close');
        }
        else {
          $(this).text('Open');
        }
      }
      $('.map-accordion-amenities').not(':animated').slideToggle();
    });

    $('.js-map-option').change(function(e){
      e.preventDefault();
      $('.js-map-accordion1').addClass('rotateIcon');
      $('.js-accordion').not(':animated').slideUp();
      var optionValue = $(this).val();
      var categoryClass = ".map-accordion-" + optionValue;
      $(categoryClass).not(':animated').slideDown();
    });


    //map and markers

    var markerIcon = L.Icon.extend({
      options: {
        iconSize:     [33, 44], // size of the icon
        iconAnchor:   [16, 44], // point of the icon which will correspond to marker's location
        popupAnchor:  [0, -48] // point from which the popup should open relative to the iconAnchor
      }
    });

    var iconsPath = "/Cwp/assets/ubin/images/";

    var mapSample = "/api/ubinapi/mapSampleData";
    var amenitySample = "/api/ubinapi/mapAmenitiesSample";
    var trailSample = "/api/ubinapi/mapTrailSample";
    var sideBarTemplate = doT.template($('#template-mapSidebar').html());

    var sidebar = L.control.sidebar('sidebar', {
      position: 'right',
      autoPan: true
    });

    function populateMap(file, callback) {
      var xobj = new XMLHttpRequest();
      xobj.overrideMimeType("application/json");
      xobj.open('GET', file, true);
      xobj.onreadystatechange = function() {
        if (xobj.readyState == 4 && xobj.status == "200") {
          callback(xobj.responseText);
        }
      };
      xobj.send(null);
    }

    function markersGroup(item) {
      this.displayName = item;
      this.pins = [];
      this.activePins = [];
      this.marker = null;
    }

    function setMapView(childLayer, parentLayer) {
      if(parentLayer.hasLayer(childLayer)) {
        parentLayer.addTo(map);
        map.fitBounds(parentLayer.getBounds(), {
          padding: [50,50]
        });
      } else {
        map.removeLayer(parentLayer);
        map.flyTo([1.4125870920831791, 103.95795822143555], 15, {
          animate: true,
          duration: 0.90
        });
      }
    }

    function addMarkersToLayer(item, childLayer, parentLayer) {
      if(childLayer.hasLayer(item)) {
        childLayer.removeLayer(item);
        if(jQuery.isEmptyObject(childLayer._layers)) {
          parentLayer.removeLayer(childLayer);
        }
      } else {
        item.addTo(childLayer);
        childLayer.addTo(parentLayer);
      }
    }
    //populating map with interest markers

    // var interestLayer;
    var iconClass = 'leaflet-marker-icon leaflet-zoom-animated leaflet-interactive interest-markers',
      normalIcon, activeIcon;

    let interestClicked = '';
    let interesetPins = [];

    //filtered data
    let currentUrl = window.location.search;
    let filteredData = [];
    const filters = queryString.parse(currentUrl);
    let filterValues = Object.values(filters);
    let filterArr = [];
    if(filterValues.length > 0) {
      filterArr = filterValues[0].split(',');
    }
    let filterPins = [];


    populateMap(mapSample, function(response){
      let interestsPinsLayer = new L.featureGroup(),
        interestsLayer = new L.featureGroup(),
        markersList = $('.interest-filter li');
      // interestsLayer.addTo(map);

      $.each(JSON.parse(response), function(ikey, ivalue){

        markersList.each(function(){
          if($(this).data('tags') == ikey) {
            $(this).text(ivalue.displayName);
          }
        });

        let markersInInterests = ivalue.data,
          normalIconUrl = iconsPath + ivalue.normalIconName,
          activeIconUrl = iconsPath + ivalue.activeIconName;

        normalIcon = new markerIcon({iconUrl: normalIconUrl});
        activeIcon = new markerIcon({iconUrl: activeIconUrl});


        if(!interesetPins[ikey]){
          interesetPins[ikey] = new markersGroup(ikey);
        }

        for(let i=0; i < markersInInterests.length; i++) {
          let markerObj = markersInInterests[i];

          let markerGpx = generateInterestMarker(markerObj, normalIcon, activeIcon);
            markerGpx.id = markerObj.id;
            interesetPins[ikey].pins.push(markerGpx);
        }
      });

      if(filterArr.length > 0) {

        $('.js-map-accordion1, .js-map-visHidden1').trigger('click');

        let pins, tag;
        filterPins =  filterArr.map(function(filter){
          if(interesetPins[filter]) {
            return interesetPins[filter];
          }
        });

        for(let i=0; i < filterPins.length; i++) {
          pins = filterPins[i].pins;

          for(let j=0; j < pins.length; j++) {
            // console.log(pin)
            addMarkersToLayer(pins[j], interestsPinsLayer, interestsLayer);
          }
        }

        for(let i=0; i <filterPins.length; i++) {
          tag = filterPins[i].displayName;

          markersList.each(function(){
            if($(this).data('tags') == tag) {
              $(this).addClass('active');
            }
          });

        }
        setMapView(interestsPinsLayer, interestsLayer);

      }

      $('.interest-filter li').on('click', function(e){
        e.preventDefault();
        let tag =$(this).data('tags'),
          pins;

        if(!$(this).hasClass('active')){
          if(interestClicked){
            interestClicked.setIcon(normalIcon);
            interestClicked._icon.className = iconClass;
          }
        }
        sidebar.hide();
        map.closePopup();

        if(tag in interesetPins) {
          pins = interesetPins[tag].pins;
          for(let i =0; i < pins.length; i++) {
            addMarkersToLayer(pins[i], interestsPinsLayer, interestsLayer);
          }
        }

        setMapView(interestsPinsLayer, interestsLayer);
      });

      function generateInterestMarker(interestData, normalIconName, activeIcon) {
        let title = interestData.title,
          sideBarContent = '',
          categories = interestData.category;

        let popUp = "<p class='popUp-title main-popUp-title'>" + title + "</p><p class='popUp-title main-popUp-category'>" + categories + "</p>";

        let marker = L.marker([interestData.latitude, interestData.longitude], {icon: normalIcon}).bindPopup(popUp).on('click', function(e){
          if(interestClicked) {
            interestClicked.setIcon(normalIcon);
            this._icon.className = iconClass;
          }

          interestClicked = e.target;
          e.target.setIcon(activeIcon);
          this.openPopup();
          this._icon.className = iconClass;

          $('.leaflet-sidebar').hide();
          sideBarContent = sideBarTemplate(interestData);
          sidebar.setContent(sideBarContent);
          $('.leaflet-sidebar').show(250);

          var visible = sidebar.isVisible();
          if(!visible) {
            sidebar.show();
          }
        }).on('mouseover', function(e){
            this.openPopup();
            if (interestClicked != e.target){
              e.target.setIcon(activeIcon);
              this._icon.className = iconClass;
              this.openPopup();
            }
          }).on('mouseout', function(e){
            if(interestClicked != e.target){
              e.target.setIcon(normalIcon);
              this._icon.className = iconClass;
              this.closePopup();
            }
          });

          map.addControl(sidebar);

          return marker;
      }

      map.on('click', function () {
        if(interestClicked){
          interestClicked.setIcon(normalIcon);
          interestClicked._icon.className = iconClass;
        }
        sidebar.hide();
        map.closePopup();
      });
    });

    //populating map with amenity markers
    let amenitiesPins = {};

    populateMap(amenitySample, function(response){

      let clicked = '';
      let amenitiesPinsLayer = new L.featureGroup(),
        amenitiesLayer = new L.featureGroup();

      //akey = amenities key, avalue = amenities value
      //first we create object with all the amenities, with pins in separate array inside the object
      //for each individual amenity
      $.each(JSON.parse(response), function(akey, avalue){

        //update the displayName from JSON
        let amenitiesList = $('.amenity-filter li');
        amenitiesList.each(function(){
          if($(this).data('tags') == akey) {
            $(this).text(avalue.displayName);
          }
        });

        let markersInAmenities = avalue.data;

        if(!amenitiesPins[akey]) {
          amenitiesPins[akey] = new markersGroup(akey);
        }


        for(let i=0; i < markersInAmenities.length; i++) {
          let markerObj = markersInAmenities[i];

          let markerGpx = generateMarkerGraphic(markerObj.latitude, markerObj.longitude, markerObj.title, iconsPath, avalue.iconName);
            markerGpx.id = markerObj.id;
            amenitiesPins[akey].pins.push(markerGpx);
        }//end of pins
      });//end of amenity

      $('.amenity-filter li').on('click', function(e){
        e.preventDefault();
        let tag =$(this).data('tags');
        let pinsLayer = new L.layerGroup();

        if(tag in amenitiesPins) {
          let pins = amenitiesPins[tag].pins;
          for(let i =0; i < pins.length; i++) {
            addMarkersToLayer(pins[i], amenitiesPinsLayer, amenitiesLayer);
          }
        }

        setMapView(amenitiesPinsLayer, amenitiesLayer);
      });

      function generateMarkerGraphic(lat, lng, title, iconsPath, iconName) {
        let popUp = "<p class='popUp-title amenity-popUp'>" + title + "</p>",
          iconsUrl = iconsPath + iconName,
          icon = new markerIcon({iconUrl: iconsUrl});
        let marker = L.marker([lat,lng],{icon: icon}).bindPopup(popUp)
          .on('click', function(e){
            clicked = e.target;
            this.openPopup();
          }).on('mouseover', function(e){
            this.openPopup();
            if (clicked != e.target){
              this.openPopup();
            }
          }).on('mouseout', function(e){
            if(clicked != e.target){
              this.closePopup();
            }
          });
        return marker;
      }
    });

    //populating map with trails
    let trailsPins = [];

    populateMap(trailSample, function(response){
      let clicked = '',
        trailsData,
        trailsPolylineLayer = new L.featureGroup(),
        trailsLayer = new L.featureGroup();

      $.each(JSON.parse(response), function(tkey, tvalue){
        //update the displayName from JSON
        let trailsList = $('.trail-filter li');
        trailsList.each(function(){
          if($(this).data('tags') == tkey) {
            $(this).text(tvalue.displayName);
          }
        });

        trailsData = tvalue.data;

        if(!trailsPins[tkey]) {
          trailsPins[tkey] = new markersGroup(tkey);
          trailsPins[tkey].color = trailsData[0].color;
        }

        for(let i=0; i < trailsData.length; i++) {
          let polylineObj = trailsData[i];

          let polylineGraphic = generatePolylineGraphic(polylineObj);
            polylineGraphic.id = polylineObj.id;
            trailsPins[tkey].pins.push(polylineGraphic);
        }//end for

      });//end each

      $('.trail-filter li').on('click', function(e){
        e.preventDefault();
        $('.trail-filter li')
        let tag = $(this).data('tags'),
          color = trailsPins[tag].color,
          border = "#919191";

        if($(this).hasClass('active')) {
          $(this).css({
            background: color,
            borderColor: color
          });
        } else {
          $(this).css({
            background: 'transparent',
            borderColor: border
          })
        }

        if(tag in trailsPins) {
          let polyline = trailsPins[tag].pins;
          console.log("color: ", trailsPins[tag].color);

          for(let i = 0; i < polyline.length; i++) {
            addMarkersToLayer(polyline[i], trailsPolylineLayer, trailsLayer);
          }
        }

        setMapView(trailsPolylineLayer, trailsLayer);
      });

      function generatePolylineGraphic(trail) {
        let title = trail.title,
          sideBarContent = '',
          popUp = "<p class='popUp-title trail-popUp'>" + title + "</p>",
          coordinates = trail.coordinates;

        let polyline = L.polyline([coordinates], {
          color: trail.color,
          weight: 5,
          smoothFactor: 10.0,
          className: trail.category
        }).bindPopup(popUp).on('click', function(e){
          clicked = e.target;
          $('.leaflet-sidebar').hide();
          sideBarContent = sideBarTemplate(trail);
          sidebar.setContent(sideBarContent);
          $('.leaflet-sidebar').show(250);
          this.openPopup();
          let visible  = sidebar.isVisible();
          if(!visible) {
            sidebar.show();
          }
        }).on('mouseover', function(e){
          this.openPopup();
          if (clicked != e.target){
            this.openPopup();
          }
        }).on('mouseout', function(e){
          if(clicked != e.target){
            this.closePopup();
          }
        });

        return polyline;
      }
    });

    $(document).on('click', '.close-btn', function(){
      if(interestClicked){
        interestClicked.setIcon(normalIcon);
        interestClicked = '';
        map.closePopup();
      }
      sidebar.hide();
    });

    //filters for interest and trails

    var interestFilterParameter = [];

    //add selected tags on page load
    var $interestFilterBtns = $('.interest-filter li');
    $interestFilterBtns.each(function(){
      if($(this).hasClass('active')){
        var tag = $(this).data('tags');
        var tagIndex = $.inArray(tag, interestFilterParameter);
        if (tagIndex < 0){
          interestFilterParameter.push(tag);      //prevent duplicate tags
        }
      }
    });

    var mapBounds = new L.LatLngBounds(new L.LatLng(1.432146588951299, 104.04361724853516), new L.LatLng(1.3807104645408492, 103.85993957519531));
    // var center = mapBounds.getCenter();
    var mapMinZoom = 12;
    var mapMaxZoom = 19;
    var map = L.map('mapdiv').setView([1.4125870920831791, 103.95795822143555], 15);
    // var map = L.map('mapdiv').fitBounds([[1.3807104645408492, 103.85993957519531], [1.432146588951299, 104.04361724853516]]);

    map.setMaxBounds([[1.432146588951299, 104.04361724853516], [1.3807104645408492, 103.85993957519531]]);

    L.tileLayer('https://maps-{s}.onemap.sg/v3/Original/{z}/{x}/{y}.png', {
                  minZoom: mapMinZoom, maxZoom: mapMaxZoom,
                  bounds: mapBounds,
                  attribution:"Map data © contributors, <a href='http://SLA.gov.sg'>Singapore Land Authority</a>",
                  opacity: 1
                  }).addTo(map);
    var attribution = map.attributionControl;
    attribution.setPrefix('<img src="https://docs.onemap.sg/maps/images/oneMap64-01.png" alt="OneMap" style="height:20px;width:20px;"/>');
  }
}
