'use strict';

import 'slick-carousel';
import doT from "dot";
import enquire from 'enquire.js';

export default class PlanTrip {
	constructor() {
		var slideToAdd = '<div class="slides slide-0"><h3>Operating hours</h3><ul><li><p>Ubin-HSBC Volunteer Hub:</p><p>8.30am - 5.00pm</p></li><li><p>Ubin Living Lab:</p><p>9.30am - 4.30pm</p></li><li><p>Chek Jawa Wetlands:</p><p>8.30am - 6.00pm</p></li></ul></div>';

		enquire.register("screen and (min-width: 961px)",{
			setup: function() {
				$('.slides-group').slick({
					infinite: true,
					autoplay: true,
					autoplaySpeed: 2500,
					slideToScroll: 1,
					dots: true,
					pauseOnHover: true,
					arrows: false,
				});
			},
			match: function() {
				$('.slides-group').slick('slickRemove', 0);
			},
			unmatch : function() {
				$('.slides-group').slick('slickAdd', slideToAdd, true);
			}
		});


		var cardInfo;

		var templateSquare = doT.template($('#template-squareImg').html());
		var templateText = doT.template($('#template-text').html());
		var templateLong = doT.template($('#template-longImg').html());

		var activeCount = 2;
		var totalCards = 6;

		var onload = '/api/ubinapi/onloadPlanTrip';
		// var onload = '/ubin/api/onloadPlanTrip.json';

		var apiUrl = $('#dynamicCard-container').data('api-endpoint');
		var filterParameters = [];

		//loop to see which filter tags are inside once
		var $filterButton = $('.plan-trip-tabs ul li');
		$filterButton.each(function() {
			if ($(this).hasClass('active')){
				var tag = $(this).data('tags');
				filterParameters.push(tag);
			}
		});

		//ajax onload

		$.ajax({
			url: onload,
			dataType: "json",
			type: "GET",
			contentType: "application/json; charset=utf-8",
			success: function(response) {
				var newCardContent = '';
        response.forEach(function(item, i) {
          let cardPlacement = i % 6;
          switch(cardPlacement){
            case 0:
              return newCardContent += templateSquare(item);
            case 1:
      				return newCardContent += templateText(item);
            case 2:
              return newCardContent += templateSquare(item);
            case 3:
              return newCardContent += templateText(item);
            case 4:
              return newCardContent += templateLong(item);
            case 5:
              return newCardContent += templateLong(item);
          }
        });

				$('.dynamicCards').html(newCardContent);
				$('.dynamicCards').css("opacity", "1");
			},

			beforeSend: function(xhr){
				if (xhr.overrideMimeType) {
					xhr.overrideMimeType("application/json");
				}
			}
		});
		$('#generateMap').on('click', function(e){
			let filters = [],
				pagePath = $('#pagePath').attr('data-url');
			$('.plan-trip-btn').each(function(){
				if($(this).hasClass('active')) {
					let tag = $(this).data('tags');
					filters.push(tag);
				}
			});

			var newPageURL = pagePath + '?filters=' + filters.toString();
			$(this).attr('href', newPageURL);
		});
		// console.log(filterParameters);
		$('.plan-trip-btn').on('click', function(e){
			e.preventDefault();
			var areaName = this.id;
			var tag = $(this).data('tags');
			var tagIndex = $.inArray(tag, filterParameters);

			let filters = [],
				pagePath = $('#pagePath').attr('data-url');

			// get active and push tag if not inside
			if (tagIndex < 0) {
				filterParameters.push(tag);
			}
			else {
				filterParameters.splice(tagIndex, 1);
			}

			if(activeCount <= 3){
				$('.dynamicCards').css("opacity", "0");
				$.ajax({
					url: apiUrl,
					dataType: "json",
					type: "POST",
					// type: "GET",
					contentType: "application/json; charset=utf-8",
					// contentType: "application/j.son; charset=utf-8",
					data: JSON.stringify({ Filters: filterParameters }),
					// data: JSON.stringify(filterParameters),
					success: function(response) {
						setTimeout(function() {
							var newCardContent = '';
              response.forEach(function(item, i) {
                let cardPlacement = i % 6;
                switch(cardPlacement){
                  case 0:
                    return newCardContent += templateSquare(item);
                  case 1:
                    return newCardContent += templateText(item);
                  case 2:
                    return newCardContent += templateSquare(item);
                  case 3:
                    return newCardContent += templateText(item);
                  case 4:
                    return newCardContent += templateLong(item);
                  case 5:
                    return newCardContent += templateLong(item);
                }
              });

							$('.dynamicCards').html(newCardContent);
							$('.dynamicCards').css("opacity", "1");
						}, 650);

					},

					beforeSend: function(xhr){
						if (xhr.overrideMimeType) {
							xhr.overrideMimeType("application/json");
						}
					}
				});


			}

		if (activeCount >= 3) {
				if($(this).is('.active')) {
					$(this).removeClass('active');
					activeCount--;
				}
				else {
					// alert("You can only select up to 3 areas of interest. Please deselect one area of interest first.");
					let targetPopup = $('#plan-trip-dialog').attr('data-popup'),
						popupMessage = $('.popup-message').data('popupMessage');

					$('.popup-message').html(popupMessage);
					$('[data-popup="' + targetPopup + '"]').fadeIn(350);

					$('[data-popup-close]').on('click', function(e) {
						e.preventDefault();
						let targetPopup = $(this).attr('data-popup-close');
						$('[data-popup="' + targetPopup + '"]').fadeOut(350);
					})
				}
			}
			else {
				if ($(this).is('.active')) {
					$(this).removeClass('active');
					activeCount--;

				}
				else {
					$(this).addClass('active');
					activeCount++;
				}
			}

		});

	}
}
