'use strict';

import $ from 'jquery';
import 'sticky-kit';

export default class Sidenav {
  constructor() {

    let $sidebar = $('.sidenav'),
        $sidebarMenu = $('.level1', $sidebar);
    // sidebar sitcky
    $sidebarMenu.stick_in_parent({
      offset_top : 0
    });
  }
}
