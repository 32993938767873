/*
* Custom Select
*/

import $ from 'jquery';

  export default function customSelect($element) {

    let removeFilterOptions = function() {
        $('.filter-options').removeClass('open');
        $('.filter-options').css({"opacity": "0", "z-index": "0", "visibility": "hidden"});
    }

    $(window).on('click touchstart', function(e){

        if(!$(e.target).closest('.events-filter').length && !$(e.target).closest('.pika-button').length
            && !$(e.target).closest('.pika-prev').length && !$(e.target).closest('.pika-next').length
            && !$(e.target).closest('.pika-select').length && !$(e.target).closest('abbr').length) {

            removeFilterOptions();
        }
    });


    return $element.each(function(){
        let $widget = $(this);

        let generateDropListFromSelect = function($select) {
            let $ulEl = $('<ul/>'),
                $liEl = $('<li/>'),
                $anchorEl = $('<a class="option" data-value=""/>');

            $select.find('option').each(function() {
            let $option = $(this),
                $newAnchorEl = $anchorEl.clone(),
                $newLiEl = $liEl.clone();

                $newAnchorEl.text($option.text());
                $newAnchorEl.attr('data-value', $option.val());

                $newAnchorEl.on('click', function(e){
                    e.preventDefault();
                    //When using .attr(), the selected attribute was not removed from the previously selected option in IE.
                    //https://stackoverflow.com/questions/19156458/jquery-attr-selected-selected-for-option-not-working-in-ie
                    //using the .prop() removes the selected attribute in IE and also in Chrome, Opera and Firefox.
                    $select.find('option').prop("defaultSelected", null);
                    $option.prop('defaultSelected', 'selected');
                    $select.trigger('change');
                });

                $newLiEl.append($newAnchorEl);
                $ulEl.append($newLiEl);
            });

            return $ulEl;
        }

        let singleSelectDropDown = function($obj) {
            let $this = $obj,
            $filter = $this.find('.filter-options'),
            $filterLabel = $this.find('.default-option'),
            $selectEl = $filter.find('.filter-select'),
            $extra = $filter.find('.custom-date');

            if($extra.length) {
                $extra.hide();
            }

            $filterLabel.on('click', function(e) {
                e.preventDefault();
                if(!$filter.hasClass('open')) {
                    $filter.css('color','#006224');
                    removeFilterOptions();
                    $filter.addClass('open');
                    $filter.css({"opacity": "1", "z-index": "10", "visibility": "visible"});
                } else {
                    $filter.css("color", "#000000");
                    $filter.removeClass('open');
                    $filter.css({"opacity": "0", "z-index": "0", "visibility": "hidden"});
                }
            });

            $filter.prepend(generateDropListFromSelect($selectEl));

            $selectEl.on('change', function(e){
                $filterLabel.text($selectEl.find('option[selected]').text());
                $filterLabel.attr('data-value', $selectEl.find('option[selected]').val());
                if($filterLabel.text().toLowerCase() === "custom date range") {
                    if($extra.is(':visible')) {
                        $extra.hide();
                        $filter.removeClass('open');
                        $this.find('.filter-options').css({"opacity": "0", "z-index": "0", "visibility": "hidden"});
                    } else {
                        $extra.show();
                    }
                } else {
                    $extra.hide();
                    $filter.removeClass('open');
                    $this.find('.filter-options').css({"opacity": "0", "z-index": "0", "visibility": "hidden"});
                }
            }).trigger('change');
        }

        $widget.find('.filter-box').each(function(){
            singleSelectDropDown($(this));
        });
    });
  };