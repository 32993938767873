'use strict';

import moment from 'moment';
import Pikaday from 'pikaday';
import Cookies from 'js-cookie';
import doT from "dot";
import _ from 'lodash';
import 'jquery-match-height';

export default class Eventslisting {
	constructor() {

		var favorites = [];
		var favCookieSet;
		var favCookieGet = Cookies.get('favorites');


		//load cookie and set active

		if(favCookieGet){
			var toArray = JSON.parse(favCookieGet);
			favorites = toArray;
			var length = favorites.length;
			for(var i = 0; i < length; i++){
				var getFavID = favorites[i];
				var favID = "#" + getFavID;
				$(favID + ' .js-fav').addClass('active');
			}
		}
		else {
			favorites = [];
		}

		//open custom date accordion
		$('.open-custom-date').on('click', function(e){
			e.preventDefault();
			$('.custom-date').not(':animated').slideToggle(300);
		});


		$('.event-card').on('mouseenter', function(e){
			$(this).addClass('active');
		});

		$('.event-card').on('mouseleave', function(e){
			if($(this).hasClass('active')) {
				$(this).removeClass('active');
			}
		});

		let eventTemplate = doT.template($('#template-eventCard').html());
		//creating array to store favorite event card

		$('.js-fav').on('click', function(e){
			e.preventDefault();
			var $parent = $(this).parent();
			var cardID = $parent.attr('id');
			if(!$(this).hasClass('active')){
				$(this).addClass('active');
				// $parent.addClass('active');
				favorites.push(cardID);
				Cookies.set('favorites', favorites, { expires: 7, path: '' });
			}
			else {
				$(this).removeClass('active');
				if($parent.hasClass('active')){
					$parent.removeClass('active');
				}
				favorites.splice($.inArray(cardID, favorites), 1);
				Cookies.set('favorites', favorites, { expires: 7, path: '' });
			}
		});


		//open View Favorite modal

		$('.js-view-fav').on('click', function(e){
			e.preventDefault();
			$('.fav-events').empty();
			$('.favoriteEvent-modal').css({"opacity": "1", "visibility": "visible"});
			// var length = favorites.length;
			// for(var i = 0; i < length; i++){
			// 	var eventCard = favorites[i];				//id
			// 	var eventCardID = "#" + eventCard;			//id with #
			// 	var cardHTML = $(eventCardID).html();
			// 	var eventCardHTML = '<div id="' + eventCard + '"class="event-card">' + cardHTML + "</div>";
			// 	$('.fav-events').append(eventCardHTML);
			// }

			$.ajax({
				url: "/api/ubinapi/favouriteevents",
				dataType: "json",
				type: "POST",
				contentType: "application/json; charset=utf-8",
				data: JSON.stringify({ Filters: favorites}),
				success: function(response) {

					let eventContent = '',
						eventKeys = Object.keys(response),
						favValues = Object.values(favorites),
						favEvents = _.intersection(eventKeys, favValues),
						noFavEvents = "No favourites selected";

					for(let i=0; i < favEvents.length; i++) {
						eventContent += eventTemplate(response[favEvents[i]]);
					}

					eventContent ? $('.fav-events').html(eventContent) : $('.fav-events').html(noFavEvents);

				}
			})
		});

		//click modal close button

		$('.modal-close').on('click', function(e){
			e.preventDefault();
			$('.favoriteEvent-modal').css({"opacity": "0", "visibility": "hidden"});
		});

		//clearing the favorite list

		$('.js-clear-fav').on('click', function(e){
			e.preventDefault();
			$('.fav-events').empty();
			favorites = [];
			Cookies.set('favorites', favorites, { expires: 7, path: '' });

			var $favIcon = $('.js-fav');
			$favIcon.each(function(){
				if($(this).hasClass('active')){
					$(this).removeClass('active');
				}
			});
		});

		let momentEventFromDate = moment();

		let eventsFromPickerOpts = {
			field: $('.js-datepicker')[0],
			format: 'DD/MM/YYYY',
			minDate: moment().toDate(),
			maxDate: moment().add(2, 'years').toDate(),
			onSelect: function(date) {
				momentEventFromDate = moment(date);

				let selectedDate = momentEventFromDate.date(),
					momentEventToDate = pickerTo.getMoment();

				pickerTo.setMinDate(momentEventFromDate.toDate());
				pickerTo.gotoDate(momentEventFromDate.toDate());

				if(pickerTo.getDate() && pickerTo.getDate() < pickerFrom.getDate()) {
					pickerTo.setDate('');
				}

				pickerTo.draw();
			}
		};

		var pickerFrom = new Pikaday(eventsFromPickerOpts);
		let eventsToPickerOpts = {
			field: $('.js-datepicker')[1],
			format: 'DD/MM/YYYY',
			minDate: pickerFrom.getMoment().toDate(),
			maxDate: moment().add(2, 'years').toDate()
		};

		var pickerTo = new Pikaday(eventsToPickerOpts);

		// if($('#custom-date-to').val()) {
		// 	pickerTo.setDate(moment($('#custom-date-to').val(), 'DD/MM/YYYY'));
		// }

		// if($('#custom-date-from').val()) {
		// 	pickerFrom.setDate(moment($('#custom-date-from').val(), "DD/MM/YYYY"));
		// 	pickerTo.setMinDate(moment($('#custom-date-from').val(), "DD/MM/YYYY"));
		// }

		$('#custom-date-to').blur(function(e){
			if($(this).val().length > 0 && $('#custom-date-from').val().length >0) {
				$('.filter-options').removeClass('open');
				$('.filter-options').animate({
					opacity: 0,
					zIndex: 0,
					visibility: "hidden"
				},250,"linear");
			}
		});

		$('#custom-date-from').blur(function(e){
			if($(this).val().length > 0 && $('#custom-date-to').val().length >0) {
				$('.filter-options').removeClass('open');
				$('.filter-options').animate({
					opacity: 0,
					zIndex: 0,
					visibility: "hidden"
				},250,"linear");
			}
		});

		$('.js-filter-eventslisting').on('click', function(e){
			e.preventDefault();
			let dateParameter = '';
			let dateFromParameter = '';
			let dateToParameter = '';
			let eventParameter = '';
			let dateAttribute = $('.filter-date span').attr('data-value');

			//get url
			let currentPageURL = window.location.href;
    		let lastindex = currentPageURL.lastIndexOf('?');
			let trimmedURL;
			if(lastindex !== -1) {
    			trimmedURL = currentPageURL.substring(0, lastindex);
			} else {
				trimmedURL = currentPageURL;
			}

			if(dateAttribute && dateAttribute === 'custom-date') {
				dateFromParameter = $('#custom-date-from').val();
				dateToParameter = $('#custom-date-to').val();
				dateParameter = 'custom-date';
			} else {
				dateFromParameter = '';
				dateToParameter = '';
				dateParameter = dateAttribute;
			}

			eventParameter= $('.filter-event span').attr('data-value');

			let filterQuery = `?dateRange=${dateParameter}&dateFrom=${dateFromParameter}&dateTo=${dateToParameter}&event=${eventParameter}`
			let newURL = `${trimmedURL}${filterQuery}`;
    		window.location.href = newURL;
		});

    //Apply matchHeight to fix the layout in events page
    $('.matchHeight').matchHeight();

	}
}
