'use strict';

import Cookies from 'js-cookie';

export default class SiteFooter {
  constructor() {

    // let subscribed = "You have already subscribed to our newsletter.",
    //     message,
    //     dataUrl = $('.footer__subscribe').data('submitUrl'),
      let sliderItem = $('.slider').find('.modal'),
        modal = $('#imageModal'),
        modalContent = modal.find('.modal-content'),
        closeBtn = modal.find('.modal-close'),
        modalBg = modal.find('.modal-background');
    // $('.subscribe-error').hide();
    //
    // if(Cookies.get('PulauUbin.Subscribed')) {
    //   // console.log('hello! i am already subscribed')
    //   // $('.subscribe-fields').hide();
    //   $('.footer__subscribe').html(subscribed);
    // }
    //
    // $('.footer__subscribe form').on('submit', function(event) {
    //   event.preventDefault();
    //   let $email = $(this).find('input[type="email"]');
    //   let email = $email.val();
    //   if(validateEmail(email)) {
    //     if(!Cookies.get('PulauUbin.Subscribed')) {
    //       Cookies.set('PulauUbin.Subscribed', 'Y');
    //     }
    //     // dataUrl = dataUrl + '?email=' + email;
    //     // $.ajax(dataUrl, {
    //     //   success: function(data) {
    //     //     // let parsedData= JSON.parse(data);
    //     //     message = data.message;
    //     //     $('.subscribe-error').stop().slideUp('fast');
    //     //     $email.removeClass('has-error');
    //     //     $('.subscribe-fields').hide();
    //     //     $('.footer__subscribe label').html(message);
    //     //   }
    //     // });
    //   }
    // });

    sliderItem.on('click', function(event) {
      event.preventDefault();
      let targ = $(event.currentTarget),
        imgSrc = targ.find('img').attr('src'),
        generatedImg = $('<img/>', {
          id: 'generatedImg',
          src: imgSrc,
          alt: 'Ubin image'
        });

      if ($(window).width() > 767) {
        modalContent.css('background-image', `url(${imgSrc})`);
      }

      modalContent.append(generatedImg);
      modal.addClass('is-active');
      $('body').addClass('stop-scrolling');
    });

    closeBtn.on('click', function() {
      hideModal();
    });

    modalBg.on('click', function() {
      hideModal();
    })

    function hideModal() {
      modal.removeClass('is-active');
      $('#generatedImg').remove();
      $('body').removeClass('stop-scrolling');
    }

    // function validateEmail(email) {
    //   var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //   return re.test(email);
    // }
  }
}
